<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 265px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">イベント<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <a @click="navigate('/admin/news')" style="cursor: pointer"
                >ダッシュボード</a
              >
            </li>
            <li>
              <a @click="navigate('/admin/event')" style="cursor: pointer">イベント</a>
            </li>
            <li><span>新規投稿</span></li>
          </ul>
        </nav>
      </div>
      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          入力内容に不備があります。<span><i class="fas fa-times msg_remove"></i></span>
        </p>
      </div>

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">確認</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span>入力途中の内容は削除されますがよろしいですか？</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          value="キャンセル"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          キャンセル
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          value="アーカイブ"
                          title="削除"
                          dusk="save"
                        >
                          実行
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
          <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
        </div>
        <!--modal_inner-->
      </div>
      <!--modal-->
      <Spinner v-if="spinner" />
      <!-- Vee-validate form field -->
      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="onSubmit"
        :validation-schema="schema"
        v-slot="{ errors }"
        class="createForm"
      >
        <div class="command_wrap command_bottom">
          <div class="leftarea btn_wrap">
            <div class="return_link">
              <a id="backToList" dusk="to_list_screen">
                <span class="icon"><i class="fas fa-chevron-left"></i></span>
                イベント一覧
              </a>
            </div>
            <div></div>
          </div>
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button @click="dateValidations" type="submit" dusk="com_save_button">
                  保存
                </button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>
        <!--command_wrap-->

        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">新規イベント</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">タイトル</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="title"
                                  type="text"
                                  class="form-control"
                                  placeholder="タイトルを入力してください"
                                  :class="{ 'is-invalid': errors.title }"
                                  v-model="eventCreate.title"
                                  id="title"
                                />
                                <ErrorMessage class="each_msg" name="title" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  :config="editorConfig"
                                  :disabled="editorDisabled"
                                  @ready="onEditorReady"
                                  @focus="onEditorFocus"
                                  @blur="onEditorBlur"
                                  @input="onEditorInput"
                                  @destroy="onEditorDestroy"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  :class="{ 'is-invalid': errors.content }"
                                  v-model="eventCreate.content"
                                  id="content"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                                <!-- <div
                                  v-if="!editorValidation"
                                  class="each_msg"
                                >
                                  本文を入力してください
                                </div> -->
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>

                      <tr>
                        <th class="wid_200px">開催日程</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_400px">
                              <p class="ttl_3">開始日時</p>
                              <div class="in_wrap time_wrap">
                                <div class="ttl_3">
                                  <Field
                                    name="start_datetime"
                                    type="datetime-local"
                                    placeholder="年月日を選択"
                                    class="input_date start_datetime"
                                    v-model="
                                      eventCreate.implementation_datetime.start_datetime
                                    "
                                  />

                                  <span style="display: none">@</span>
                                  <input
                                    style="display: none"
                                    name="public_hour"
                                    type="text"
                                    placeholder="00"
                                    class="hour start_datetime"
                                  />
                                  <span style="display: none">:</span>
                                  <input
                                    style="display: none"
                                    name="public_sec"
                                    type="text"
                                    placeholder="00"
                                    class="minutes start_datetime"
                                  />

                                  <ErrorMessage class="each_msg" name="start_datetime" />
                                  <ErrorMessage class="each_msg" name="start_hour" />
                                  <ErrorMessage class="each_msg" name="start_sec" />
                                </div>

                                <div class="msg_wrap">
                                  <span class="each_msg" v-if="startDateFormatValidation"
                                    >開始日には終了日以前の日付をご利用ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>

                                <div class="msg_wrap" v-if="dateValidated === false">
                                  <span class="each_msg"
                                    >開始日を入力ください。<span class="trg"></span
                                  ></span>
                                </div>
                                <div class="msg_wrap" v-if="hoursValidated === false">
                                  <span class="each_msg"
                                    >開始時間（時）入力ください。<span class="trg"></span
                                  ></span>
                                </div>
                                <div class="msg_wrap" v-if="minutesValidated === false">
                                  <span class="each_msg"
                                    >開始時間（分）を入力ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>
                              </div>
                              <!--in_wrap-->
                            </div>
                          </div>
                          <div class="form_outwrap">
                            <div class="wrap wid_400px">
                              <p class="ttl_3">終了日時</p>
                              <div class="in_wrap time_wrap">
                                <div class="ttl_3">
                                  <Field
                                    name="end_datetime"
                                    type="datetime-local"
                                    placeholder="年月日を選択"
                                    class="input_date end_datetime"
                                    v-model="
                                      eventCreate.implementation_datetime.end_datetime
                                    "
                                  />

                                  <span style="display: none">@</span>
                                  <input
                                    style="display: none"
                                    name="public_hour"
                                    type="text"
                                    placeholder="00"
                                    class="hour end_datetime"
                                  />
                                  <span style="display: none">:</span>
                                  <input
                                    style="display: none"
                                    name="public_sec"
                                    type="text"
                                    placeholder="00"
                                    class="minutes end_datetime"
                                  />

                                  <!-- <ErrorMessage class="each_msg" name="end_date" /> -->
                                  <ErrorMessage class="each_msg" name="end_hour" />
                                  <ErrorMessage class="each_msg" name="end_sec" />
                                </div>
                                <!--datepickbox-->

                                <div class="msg_wrap" v-if="false === enddateValidated">
                                  <span class="each_msg"
                                    >終了日を入力ください。<span class="trg"></span
                                  ></span>
                                </div>
                                <div class="msg_wrap" v-if="endDateFormatValidation">
                                  <span class="each_msg"
                                    >終了日には開始日以降の日付をご利用ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>

                                <div class="msg_wrap" v-if="false === endhoursValidated">
                                  <span class="each_msg"
                                    >終了時間（時）入力ください。<span class="trg"></span
                                  ></span>
                                </div>
                                <div
                                  class="msg_wrap"
                                  v-if="false === endminutesValidated"
                                >
                                  <span class="each_msg"
                                    >終了時間（分）を入力ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>
                              </div>
                              <!--in_wrap-->
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">開催場所</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="place"
                                  type="text"
                                  class="form-control"
                                  placeholder="三宮駅前○○ホール"
                                  v-model="eventCreate.place"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th class="wid_200px">最大参加人数</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_45per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="capacity"
                                  type="text"
                                  v-on:keypress="NumbersOnly"
                                  class="form-control"
                                  placeholder="20"
                                  v-model="eventCreate.capacity"
                                  :class="{ 'is-invalid': errors.capacity }"
                                />
                                <ErrorMessage class="each_msg" name="capacity" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">イベントお問い合せ先</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="wid_400px"></div>
                                <Field
                                  name="contact"
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  v-model="eventCreate.contact"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc"></div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn" dusk="zairyo_add"
                              ><i class="material-icons"></i
                              ><i class="fa fa-plus" aria-hidden="true"></i>
                              関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap right">
              <h2 class="ttl_2">公開情報</h2>
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">アイキャッチ</p></div>
                <div class="in_wrap">
                  <div class="filegroup m_b20">
                    <input
                      type="file"
                      id="ec_pls"
                      name="eyecatch"
                      value=""
                      class="ec_catch"
                      accept=".jpg"
                    />
                    <label for="ec_pls" class="filelabel">ファイルを選択</label>
                    <input type="hidden" name="ec_pastdel" value="del" />
                  </div>
                </div>
                <div id="prev"></div>
              </div>
              <div class="form_outwrap">
                <div class="wrap wid_100per p_r0">
                  <div><p class="ttl_3">公開ステータス</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        class="form-control"
                        v-model="eventCreate.status"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0" style="display: none">
                  <div><p class="ttl_3">カテゴリ</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        name="type"
                        as="select"
                        class="form-control"
                        v-model="eventCreate.type"
                      >
                        <option value="event">イベント</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="type" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap p_r0" style="display: none">
                  <p class="ttl_3">公開日付</p>
                  <div class="in_wrap time_wrap">
                    <div class="ttl_3">
                      <input
                        name="published_at"
                        type="datetime-local"
                        placeholder="年月日を選択"
                        class="input_date published_time"
                      />

                      <span style="display: none">@</span>
                      <input
                        style="display: none"
                        name="public_hour"
                        type="text"
                        placeholder="00"
                        class="hour published_time"
                      />
                      <span style="display: none">:</span>
                      <input
                        style="display: none"
                        name="public_sec"
                        type="text"
                        placeholder="00"
                        class="minutes published_time"
                      />
                    </div>
                    <!-- <div class="msg_wrap" v-if="!dateValidated">
                      <span class="each_msg"
                        >公開日付には 年月日を入力ください。（例：2021/10/28）。<span
                          class="trg"
                        ></span
                      ></span>
                    </div>
                    <div class="msg_wrap" v-if="!hoursValidated">
                      <span class="each_msg"
                        >公開時間（時）入力ください。<span class="trg"></span
                      ></span>
                    </div>
                    <div class="msg_wrap" v-if="!minutesValidated">
                      <span class="each_msg"
                        >公開時間（分）を入力ください。<span class="trg"></span
                      ></span>
                    </div> -->
                    <!--datepickbox-->
                  </div>
                  <!--in_wrap-->
                </div>
                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>

            <div class="box_wrap left" style="display: none">
              <h2 class="ttl_2">申込フォーム</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_20per">申込フォームの利用</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per m_t5">
                              <p class="ttl_3">
                                <small class="input_description"
                                  >※利用するにチェックを入れた場合、イベントページに申込リンクが表示されます</small
                                >
                              </p>
                              <div class="in_wrap">
                                <label dusk="event_reservation_form_check">
                                  <input
                                    id="chk_use"
                                    type="checkbox"
                                    name="event_reservation_form"
                                    value="1"
                                    class="chkbox"
                                  /><span></span><span class="chktxt">利用する</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr class="event_form_usecase" style="display: none">
                        <th class="wid_20per">入力項目の追加</th>
                        <td>
                          <div id="item_add_wrap" class="form_outwrap item_form">
                            <p class="ttl_3">項目名</p>
                          </div>
                          <div class="form_outwrap item_form">
                            <p class="commandtext t_c m_t10 m_b40 item_form">
                              <span class="item_add_btn" dusk="event_form_item_add_btn"
                                ><i class="material-icons">add</i
                                >申込フォームに項目を追加する</span
                              >
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
    <template id="item_doc_Template">
      <div class="row_wrap new_item sort_item">
        <div class="col">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <input
                  type="hidden"
                  name="jdg_val[]"
                  class="fileHiddenName"
                  value="new"
                />
                <input
                  name="rel_doc_title[]"
                  type="text"
                  class="fileName uploadFileName"
                  required
                  placeholder="例：1月の営業スケジュールについて"
                  value=""
                />
              </div>
              <div class="fileDisplayName each_msg"></div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="col item_list">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <div class="filegroup">
                  <input
                    type="file"
                    name="rel_doc[]"
                    class="input-file"
                    style="display: none"
                    accept=".pdf"
                    required
                  />
                  <label class="filelabel fileup-btn">ファイルを選択</label>
                  <span class="filename chooseFileNameError">選択してください</span>
                </div>
              </div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="icon col wid_50px t_c">
          <div class="remove_wrap">
            <a
              href=""
              class="item_doc_remove_btn item_remove_btn remove_btn tooltip tip-left"
              title="削除"
              ><i class="fas fa-times"></i
            ></a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import moment from "moment";

import { mapState, mapGetters, mapActions } from "vuex";

import $ from "jquery";

import Spinner from "../../../components/control_panel/Spinner.vue";
import "@ckeditor/ckeditor5-build-classic/build/translations/ja";

let schema;
export default {
  name: "Event Create",
  setup() {
    /**
     * Validation setup
     * @author Manikandan
     *  @date 16/11/2021
     */
    schema = Yup.object().shape({
      title: Yup.string().required("タイトルを入力してください。"),
      // capacity: Yup.string().required("最大参加人数を入力してください。"),
      status: Yup.string().required("ステータスを選択してください。"),
      content: Yup.string().required("本文を入力してください。"),
    });

    return {
      schema,
    };
  },
  computed: {
    ...mapState("event", ["allEvent"]),
    ...mapState("user", ["loginUser"]),
    ...mapState("common", ["spinner"]),
  },
  data() {
    return {
      eventCreate: {
        content_type: "html",
        from: "admin",
        content: "",
        // fcfs: false,
        // is_fcfs: false,
        implementation_datetime: {
          start_datetime: null,
          end_datetime: null,
        },
        reception_period: {
          start_datetime: null,
          end_datetime: null,
        },
        status: "公開",
        from_id: "",
        from_type: "users",
        type: "event",
      },

      editor: ClassicEditor,
      editorConfig: {
        language: "ja",
      },
      editorValidation: true,
      fileData: [],
      formInputEvent: false,
      fileDisplayNameError: true,
      date: "",
      hours: "00",
      minutes: "00",

      hoursValidated: null,
      minutesValidated: null,
      dateValidated: null,
      enddateValidated: true,
      endhoursValidated: null,
      endminutesValidated: null,
      publisheddateValidated: null,
      startGreatThen: false,
      endLessThen: false,

      commonValidationError: false,
      fileUploadError: true,
      fileDisplayError: true,
      startDateTimeCompountValue: {
        date: null,
        hours: "00",
        minutes: "00",
      },
      endDateTimeCompountValue: {
        date: null,
        hours: "00",
        minutes: "00",
      },
      publishedDateTimeCompountValue: {
        date: null,
        hours: "00",
        minutes: "00",
      },
      startDateFormatValidation: false,
      endDateFormatValidation: false,
      userImageUpload: "",
    };
  },

  components: {
    Form,
    Field,
    ErrorMessage,
    Spinner,
  },
  created() {
    this.eventCreate.from_id = this.loginUser ? this.loginUser.id : "";
  },
  methods: {
    ...mapActions("event", ["createEvent"]),
    ...mapActions("common", ["setSpinnerStatus", "setSucccessMessageStatus"]),

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    dateValidations() {
      if (
        this.dateValidated !== true ||
        this.startDateFormatValidation !== false ||
        this.endDateFormatValidation !== false
      ) {
        this.dateValidated = this.dateValidated === true ? true : false;
        this.startDateFormatValidation =
          this.startDateFormatValidation === false ? false : true;
        this.endDateFormatValidation =
          this.endDateFormatValidation === false ? false : true;
        return false;
      }
      return true;
    },
    /**
     * submit the event data
     * @author Manikandan
     *  @date 16/11/2021
     */
    onSubmit() {
      if (!this.dateValidations()) {
        return false;
      }
      if (this.fileUploadError && this.fileDisplayError) {
        this.commonValidationError = false;

        this.setSpinnerStatus(true);

        this.eventCreate.implementation_datetime.start_datetime =
          this.startDateTimeCompountValue.date + ":00";

        this.eventCreate.reception_period.start_datetime =
          this.startDateTimeCompountValue.date + ":00";

        if (
          this.endDateTimeCompountValue.date !== "" &&
          this.endDateTimeCompountValue.date != null
        ) {
          this.eventCreate.implementation_datetime.end_datetime =
            this.endDateTimeCompountValue.date + ":00";

          this.eventCreate.reception_period.end_datetime =
            this.endDateTimeCompountValue.date + ":00";
        } else {
          this.eventCreate.implementation_datetime.end_datetime =
            moment(this.startDateTimeCompountValue.date).format("YYYY-MM-DD") +
            " 23:59:59";

          this.eventCreate.reception_period.end_datetime =
            moment(this.startDateTimeCompountValue.date).format("YYYY-MM-DD") +
            " 23:59:59";
        }
        $("#js-loader").fadeIn();
        this.createEvent({
          event: this.eventCreate,
          files: this.fileData,
          eyeCatch: this.userImageUpload,
        });
        $("#js-loader").fadeOut();
        this.setSucccessMessageStatus({
          status: true,
          message: "イベントが正常に作成されました。",
        });
      }
    },

    /**
     * On editor input for CKEditor
     * @author Manikandan
     *  @date 16/11/2021
     */
    onEditorInput( data, event, editor ) {

       editor.editing.view.document.on( 'enter', ( evt, data ) => {

			if ( data.isSoft ) {
				editor.execute( 'enter' );
			} else {
				editor.execute( 'shiftEnter' );
       }

			data.preventDefault();
			evt.stop();
			editor.editing.view.scrollToTheSelection();

		}, { priority: 'high' } );

    },
    /**
     * discurd the changes
     * @author Manikandan
     *  @date 16/11/2021
     */
    discardModal() {
      this.$router.push({ path: "/admin/event" });
    },
    /**
     * close discurd confirm popup
     * @author Manikandan
     *  @date 16/11/2021
     */
    closeDiscard() {
      $("#discardModal").removeClass("open");
    },
    /**
     * Navigate bredcubms routes
     * @author Manikandan
     *  @date 16/11/2021
     */
    navigate(url) {
      if (this.formInputEvent == true) {
        $("#discardModal").addClass("open");
      } else {
        this.$router.push({ path: url });
      }
    },
    validateDate(dateString) {
      return moment(dateString, "YYYY-MM-DDTHH:mm", true).isValid();
    },
  },
  mounted() {
    let vueThis = this;
    let inputDirty = false;

    /**
     * form input changes find
     * @author Manikandan
     *  @date 16/11/2021
     */
    $("form").change(function () {
      inputDirty = true;
      vueThis.formInputEvent = true;
    });

    /**
     * back to list naviation route
     * @author Manikandan
     *  @date 16/11/2021
     */
    $("#backToList").click(function () {
      if (inputDirty == true) {
        $("#discardModal").addClass("open");
      } else {
        vueThis.$router.push({ path: "/admin/event" });
      }
    });

    /**
     * File change validation
     * @author Manikandan
     *  @date 16/11/2021
     */
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileDisplayName = $el.closest(".row_wrap,.new_item").find(".uploadFileName");

      var file = event.target.files[0];

      // if (file.size > 2000000) {
      //   $(this).siblings(".filename").text("2 mb以下のファイルを指定してください。");
      //   $(this).siblings(".filename").addClass("each_msg");
      //   return false;
      // }

      $(this).siblings(".filename").text("選択されていません");

      var permit_type = ["application/pdf"];
      if (file && permit_type.indexOf(file.type) == -1) {
        alert("アップロードできるファイルの形式はpdfです");
        //if文で選んだあとに再選択書く
        $(this).val("");
        return;
      }

      $(this).siblings(".filename").text($(this).prop("files")[0].name);
      $(this).siblings(".filename").removeClass("each_msg");
    });

    $(document).on("click", "button[type=submit]", function (e) {
      var $el = $(e.currentTarget);
      var $file = $el.closest("form").find(".row_wrap.new_item");

      vueThis.fileData = [];

      $file.each(function () {
        if ($(this).find(".uploadFileName").val() == "") {
          $(this).find(".fileDisplayName").html("ファイル名を入力してください");
          vueThis.fileDisplayError = false;
        } else {
          $(this).find(".fileDisplayName").html("");
          vueThis.fileDisplayError = true;
        }

        if ($(this).find(".input-file").val() == "") {
          $(this).find(".chooseFileNameError").addClass("each_msg");
          $(this).find(".chooseFileNameError").html("ファイルを入力してください");
          vueThis.fileUploadError = false;
        } else {
          $(this).find(".chooseFileNameError").removeClass("each_msg");
          $(this).find(".chooseFileNameError").html();
          vueThis.fileUploadError = true;
        }

        if (
          $(this).find(".uploadFileName").val() != "" &&
          $(this).find(".input-file").val() != ""
        ) {
          let fileObject = new FormData();

          fileObject.append("key", "event_attached_file");

          fileObject.append("display_name", $(this).find(".uploadFileName").val());

          fileObject.append("uploadfile", $(this).find(".input-file").prop("files")[0]);

          vueThis.fileData.push(fileObject);
        }
      });
    });

    /**
     * file view delete option
     * @author Manikandan
     *  @date 16/11/2021
     */
    $(document).on("click", ".item_doc_remove_btn", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".row_wrap,.new_item");
      $row.remove();

      var $fileDisplayName = $el.closest(".row_wrap,.new_item").find(".fileName");

      for (var i = 0; i < vueThis.fileData.length; i++) {
        if (vueThis.fileData[i].display_name == $fileDisplayName.val()) {
          vueThis.fileData.splice(i, 1);
          break;
        }
      }
      return false;
    });

    $(function () {
      $(document).on("blur", ".input_date.start_datetime", function () {
        let date = $(this).val().replace(/\//g, "-");
        if (date == "" || date == null) {
          vueThis.dateValidated = false;
        }
      });
      $(document).on("blur", ".input_date.end_datetime", function () {
        let date = $(this).val().replace(/\//g, "-");
        if (date == "" || date == null) {
          vueThis.enddateValidated = true;
        }
      });
      $(document).on("change", ".input_date.start_datetime", function () {
        let date = $(this).val().replace(/\//g, "-");
        vueThis.startDateTimeCompountValue.date = date.replace("T", " ");
      });

      $(document).on("change", ".input_date.start_datetime", function () {
        var date = vueThis.validateDate($(this).val());

        let startDate = Date.parse($(this).val());

        let endDate = vueThis.endDateTimeCompountValue.date;

        if (date) {
          vueThis.dateValidated = true;
          vueThis.date = $(this).val().replace(/\//g, "-");

          if (endDate != null && endDate != "") {
            if (startDate <= Date.parse(endDate)) {
              vueThis.startDateFormatValidation = false;
              vueThis.endDateFormatValidation = false;
            } else {
              vueThis.startDateFormatValidation = true;
            }
          } else {
            vueThis.startDateFormatValidation = false;
          }
        } else {
          vueThis.dateValidated = false;
          return false;
        }
      });

      $(document).on("change", ".input_date.end_datetime", function () {
        var date = vueThis.validateDate($(this).val());

        let endDate = Date.parse($(this).val());

        let startDate = vueThis.startDateTimeCompountValue.date;

        if (date) {
          vueThis.enddateValidated = true;

          if (startDate != null && startDate != "") {
            if (endDate >= Date.parse(startDate)) {
              vueThis.endDateFormatValidation = false;
              vueThis.startDateFormatValidation = false;
            } else {
              vueThis.endDateFormatValidation = true;
            }
          } else {
            vueThis.endDateFormatValidation = false;
          }
        } else {
          vueThis.enddateValidated = false;
          return false;
        }
      });

      //end time loader
      $(document).on("change", ".input_date.end_datetime", function () {
        let date = $(this).val().replace(/\//g, "-");
        vueThis.endDateTimeCompountValue.date = date.replace("T", " ");
      });

      $(document).on("change", ".input_date.end_datetime", function () {
        var date = vueThis.validateDate($(this).val());
        if (date) {
          vueThis.enddateValidated = true;
          vueThis.date = $(this).val().replace(/\//g, "-");
          return true;
        } else {
          vueThis.enddateValidated = false;
          return false;
        }
      });

      $(document).on("change", ".input_date.published_time", function () {
        let date = $(this).val().replace(/\//g, "-");
        vueThis.publishedDateTimeCompountValue.date = date;
      });

      $(document).on("change", ".input_date.published_time", function () {
        var date = vueThis.validateDate($(this).val());
        if (date) {
          vueThis.publisheddateValidated = true;
          vueThis.date = $(this).val().replace(/\//g, "-");
          return true;
        } else {
          vueThis.publisheddateValidated = false;
          return false;
        }
      });
    });

    $(document).on("click", "button[type=submit]", function () {
      if ($("#title").val() == "" || $("#content").val() == "") {
        vueThis.commonValidationError = true;
      } else {
         $("#js-loader").fadeIn();
         vueThis.commonValidationError = false;
         $("#js-loader").fadeOut();
        
      }
    });

    $(document).on("change", ".ec_catch", function (e) {
      var file = $(this).prop("files")[0];
      let fileFormData = new FormData();
      if (file) {
        fileFormData.append("key", "eyecatch");
        fileFormData.append(
          "display_name",
          $(this)
            .val()
            .replace(/C:\\fakepath\\/i, "")
        );
        fileFormData.append("uploadfile", file);
        vueThis.userImageUpload = fileFormData;
      }
    });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
